import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ModalService } from '@services/modal.service';

import { ImgModal } from './img-modal.component';

@Directive({
  selector: '[imgModal]',
  standalone: true,
})
export class ImgModalDirective {
  @Input() imgModalSrc: string;
  opened = false;
  @HostListener('click', ['$event']) async onClick($event) {
    const src = this.imgModalSrc || this.el.nativeElement.src;
    if (!src && this.opened) return;
    this.opened = true;
    const modal = await this.modalService.createCSModal(
      ImgModal,
      {
        src,
      },
      {
        common: {
          id: 'img-modal',
          handle: false,
          animated: false,
        },
      }
    );
    await modal.present();
    await modal.onWillDismiss();
    this.opened = false;
  }

  constructor(private el: ElementRef, private modalService: ModalService) {}
}
